<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>{{ searchTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- <v-menu transition="slide-y-transition" left bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text tile v-on="on">
            <span class="d-none d-sm-flex mr-1">Сортировка</span>
            <v-icon>$vuetify.icons.sort</v-icon>
          </v-btn>
        </template>
        <v-list class="text-right">
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, index) in sortings"
              :key="index"
              :class="item.val === sort ? 'active v-list-item--active' : ''"
              @click="sortSearch(item.val)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>-->
    </v-toolbar>
    <v-container>
      <v-row align="center">
        <no-content v-if="books.length == 0" route-name title="Произведений не найдено!"></no-content>
        <book-card v-for="(book, index) in books" :key="book.id" :book="book" :index="index"></book-card>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookCard from "./BookCard.vue";

export default {
  components: {
    BookCard,
  },
  data: () => ({
    searchString: "",
    sort: "new",
    sortings: [
      { text: "По новизне", val: "new" },
      { text: "По популярности", val: "popular" },
      { text: "По комментариям", val: "comments" },
    ],
    page: 1,
    pages: 0,
    loading: false,
    searchTitle: "Поиск",
  }),
  methods: {
    getBooks() {
      var app = this;

      this.$store
        .dispatch("getBooksSearch", {
          search: app.searchString,
          page: app.page,
        })
        .then((response) => {
          if (response.data.success == 1) {
            app.pages = response.data.books.last_page;
          }
        })
        .catch(() => {
        });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          params: { search: this.searchString },
          query: { page: nextPage },
        });
      }
    },
    sortSearch(type) {
      if (type != this.sort) {
        this.sort = type;
        this.$router.push({
          name: this.$route.name,
          params: { slug: this.bookSlug },
          query: { page: this.page, sort: this.sort },
        });
      }
    },
  },
  computed: {
    ...mapGetters(["loadPage", "books"]),
  },
  created() {
    this.searchString = this.$route.params.search
      ? this.$route.params.search
      : "";
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "new";
    if (this.searchString) {
      this.searchTitle = this.searchTitle + ": " + this.searchString;
    }

    this.getBooks();
  },
};
</script>
